<template>
  <div class="case-content">
    <div class="case-title">申请人列表</div>
    <el-table :data="proposerList" style="width: 100%; margin-top: 66px; flex: 1;" height="1%" size="medium"
              :header-cell-style="{fontWeight: 'normal', height: '50px', color: '#444444', background: '#F5F7FA',fontSize: '16px'}"
              :cell-style="{fontSize: '14px',color: '#666666',height: '70px'}">
      <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
      <el-table-column prop="name" align="center" label="姓名">
        <template slot-scope="scope">
          <span class="text-overflow">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="apply_platform" align="center" label="学校/机构">
        <template slot-scope="scope">
          <span class="text-overflow">{{scope.row.apply_platform}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="position" align="center" label="职位">
        <template slot-scope="scope">
          <span class="text-overflow">{{scope.row.position}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" align="center" label="手机"></el-table-column>
      <el-table-column prop="email" align="center" label="邮箱">
        <template slot-scope="scope">
          <span class="text-overflow">{{scope.row.email}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="220">
        <template slot-scope="scope">
          <el-button type="primary" size="small" plain @click="examineDispose(scope.row.id)">查看</el-button>
          <el-button type="primary" size="small" v-if="scope.row.status === 0 || scope.row.status === 1" plain>已处理</el-button>
          <el-button type="primary" size="small" @click="disposeBtn(scope.row.id)" v-if="scope.row.status === 2">处理</el-button>
          <el-button type="danger" size="small" @click="deleteDispose(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="处理人信息" :visible.sync="lookDialog" width="550px" custom-class="look-box"
               @close="closeLookDialog" :close-on-click-modal="false">
      <el-form :model="disposeForm" :rules="disposeRules" ref="disposeForm">
        <el-form-item prop="handle_name" label="名字">
          <el-input v-model="disposeForm.handle_name" style="width: 400px;" placeholder=""></el-input>
        </el-form-item>
        <el-form-item prop="handle_phone" label="手机">
          <el-input v-model="disposeForm.handle_phone" style="width: 400px;" placeholder=""></el-input>
        </el-form-item>
        <el-form-item style="text-align: center;margin-bottom: 0;padding-top: 38px">
          <el-button class="btn-green dispose-btn" @click="submitDispose(2)">联系</el-button>
          <el-button class="dispose-btn" type="danger" @click="submitDispose(1)">忽略</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="申请人信息" :visible.sync="examineDialog" width="650px" custom-class="examine-box" :close-on-click-modal="false">
      <div class="examine-detail">
        <div class="detail-top">
          <div class="top-item"><span>姓名：</span><span>{{disposeMessage.name}}</span></div>
          <div class="top-item"><span>学校/机构：</span><span class="text-overflow">{{disposeMessage.apply_platform}}</span></div>
          <div class="top-item"><span>职位：</span><span>{{disposeMessage.position}}</span></div>
          <div class="top-item"><span>手机：</span><span>{{disposeMessage.phone}}</span></div>
          <div class="top-item"><span>邮箱：</span><span>{{disposeMessage.email}}</span></div>
          <div class="top-item"><span>更多：</span><span class="text-overflow">{{disposeMessage.demand}}</span></div>
        </div>
        <div class="detail-bottom">
          <div class="bottom-left">
            <el-button type="primary" size="small" plain>{{disposeMessage.status_name}}</el-button>
          </div>
          <div class="bottom-right" v-if="disposeMessage.status !== 2">
            <div class="right-top">
              <span>处理人：</span>
              <span>{{disposeMessage.handle_name}}</span>
            </div>
            <div class="right-bottom">
              <span>手机：</span>
              <span>{{disposeMessage.handle_phone}}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-pagination
        class="pages-center"
        :current-page="proposerPages.currentPageNum"
        :page-size="proposerPages.eachPageNum"
        layout="prev, pager, next, jumper"
        :total="proposerPages.total"
        @current-change="proposerCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import {getApplyList, alterApplyStatus, deleteApplyStatus, getApplyStatus} from '@/utils/apis'
export default {
  name: "applicantInfoIndex",
  data(){
    var isMobileNumber= (rule, value, callback) => {
      if (!value) {
        return new Error("请输入电话号码");
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {//判断是否为数字
          value = value.toString(); //转换成字符串
          if (value.length < 0 || value.length > 12 || !isPhone) { //判断是否为11位手机号
            callback(new Error("手机号码格式如:138xxxx8754"));
          } else {
            callback();
          }
        } else {
          callback(new Error("请输入电话号码"));
        }
      }
    };
    return {
      proposerList:[],
      proposerPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total:0
      },
      lookDialog:false,
      disposeForm:{
        id:'',
        handle_name:'',
        handle_phone:''
      },
      disposeRules:{
        handle_name:[
          { required: true, message: '请输入处理人姓名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字', trigger: 'blur' }
        ],
        handle_phone:[
          { required: true, message: '请输入申请人电话', trigger: 'blur' },
          { validator:isMobileNumber,trigger: 'blur' }
        ]
      },
      disposeMessage:{},
      examineDialog:false
    }
  },
  mounted() {
    this.getProposerList();
  },
  methods:{
    closeLookDialog(){
      this.$refs.disposeForm.resetFields();
    },
    deleteDispose(row){
      this.$confirm(`是否确认删除【${row.name}】`, '删除申请人',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        deleteApplyStatus(row.id).then((res)=>{
          this.$message({
            message:res.msg,
            type:'success',
            duration:1000,
            onClose:()=>{
              this.getProposerList()
            }
          })
        }).catch((err)=>{
          console.log('err',err)
        })
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    examineDispose(id){
      getApplyStatus(id).then((res)=>{
        this.disposeMessage = res.data;
        this.examineDialog = true;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    disposeBtn(id){
      this.disposeForm.id = id;
      this.lookDialog = true;
    },
    submitDispose(status){
      this.$refs['disposeForm'].validate((valid)=>{
        if(valid){
          let params = {
            handle_name:this.disposeForm.handle_name,
            handle_phone:this.disposeForm.handle_phone,
            status:status
          }
          alterApplyStatus(this.disposeForm.id,params).then((res)=>{
            this.$message({
              message:res.msg,
              type:'success',
              duration:1000,
              onClose:()=>{
                this.$refs.disposeForm.resetFields();
                this.disposeForm.id = '';
                this.lookDialog = false;
                this.getProposerList();
              }
            })
          }).catch((err)=>{
            console.log('err',err)
          })
        } else {
          return false
        }
      })
    },
    getProposerList(){
      let params = {
        type:2,
        paging:1,
        page:this.proposerPages.currentPageNum,
        pageSize:this.proposerPages.eachPageNum
      }
      getApplyList({params}).then((res)=>{
        this.proposerList = res.data.list;
        this.proposerPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    proposerCurrentChange(val){
      this.proposerPages.currentPageNum = val;
      this.getProposerList();
    }
  }
}
</script>

<style scoped lang="scss">
.case-content{
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding:0 20px;
  box-sizing: border-box;
  .case-title{
    border-bottom: 1px solid #dcdcdc;
    padding: 30px 0;
    box-sizing: border-box;
    font-size: 16px;
    color: #222;
  }
}
.dispose-btn{
  padding: 12px 26px;
  font-size: 14px;
}
::v-deep .look-box{
  border-radius: 6px;
  padding: 30px 28px 46px;
  .el-dialog__header{
    padding: 0 0 12px 0;
    border-bottom: 1px solid #DDDDDD;
    .el-dialog__headerbtn{
      right: 25px;
      top: 30px;
      .el-icon-close{
        font-size: 22px;
        color: #666666;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body{
    padding: 50px 0 0 0;
  }
}

::v-deep .examine-box{
  border-radius: 6px;
  padding: 30px 28px 20px;
  .el-dialog__header{
    padding: 0 0 12px 0;
    border-bottom: 1px solid #DDDDDD;
    .el-dialog__headerbtn{
      right: 25px;
      top: 30px;
      .el-icon-close{
        font-size: 22px;
        color: #666666;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body{
    padding: 22px 0 32px 0;
  }
}

.examine-detail{
  display: flex;
  flex-direction: column;
  .detail-top{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 30px;
    .top-item{
      background: #F8F8F8;
      border-radius: 4px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      &:nth-child(1){
        margin-top: 0;
      }
      span{
        font-size: 16px;
        font-weight: 400;
        line-height: 40px;
        &:nth-child(1){
          color: #444444;
          width: 100px;
          text-align: right;
        }
        &:nth-child(2){
          color: #666666;
        }
      }
    }
  }
  .detail-bottom{
    margin-top: 20px;
    display: flex;
    .bottom-right{
      padding: 24px 0;
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      background: #F8F8F8;
      border-radius: 4px;
      flex: 1;
      width: 1%;
      .right-top, .right-bottom{
        display: flex;
        align-items: center;
        span{
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
          &:nth-child(1){
            color: #444444;
            width: 100px;
            text-align: right;
          }
          &:nth-child(2){
            color: #666666;
          }
        }
      }
      .right-bottom{
        margin-top: 33px;
      }
    }
  }
}
</style>